<template>
  <div class="col-lg-8 padCustom">
    <!-- custom Html Start-->
    <div class="sectionTwoBoxWrap">
      <div class="insideItemBoxWrap">
        <draggable
          v-model="dashboardSort.section_2"
          class="section-two row"
          group="cards-tag"
          :class="isDisable ? 'section-two-in-active' : ''"
          :disabled="isDisable"
          @change="updateOrder"
        >
          <div
            v-for="(row, i) in dashboardSort?.section_2"
            :key="i"
            :loading="loading"
            class="col-lg-6"
          >
            <div class="largeItemBoxWrap">
              <div class="largeItemBoxHeading">
                {{ row.card_name }}
                <span
                  v-if="row.card_name == 'Trips'"
                  class="open-link"
                  @click="editItem()"
                >Detail</span>
              </div>
              <div
                v-if="row.card_name == 'Trips'"
                class="insideLargeItemBoxContentWrap"
              >
                <div class="apexBasicChart">
                  <ApexBarChartWithPlotOptions
                    :options="options"
                    :data="dashboadVehicleTrips.trips_chart_data"
                    :width="'100%'"
                    :height="'200px'"
                  />
                </div>
                <div
                  v-for="(val, st) in row.keys"
                  :key="st"
                  :class="val == 'active_trips' && 'active'"
                  class="largeItemContentBoxWrap tripsContentBoxes"
                  @click="val == 'active_trips' && openActiveModal()"
                >
                  <div :class="['largeItemsContentBoxes' ,getIconColor(val)]">
                    <div class="iconBox">
                      <Icons :name="getIconName(val)" />
                    </div>
                    <div class="contentBox">
                      <p :class="(val == 'active_trips')?'open-link':''">
                        <span>Total</span> {{ $t(`${val}`) }}
                      </p>
                    </div>
                    <div class="valueBox tripsValueBox">
                      {{ dashboadVehicleTrips[val] }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-else-if="row.card_name === 'Vehicle Status'"
                :class="['insideLargeItemBoxContentWrap', { 'active': options.tenant_id }]"
              >
                <div class="apexDonutChart">
                  <ApexDonutChart
                    :data="dashboadVehicleStatus"
                    :titles="row.keys"
                    :width="'100%'"
                    :height="'226px'"
                  />
                </div>
                <div
                  v-for="(val, st) in row.keys"
                  :key="st"
                  :class="val"
                  class="largeItemContentBoxWrap"
                  @click="(options.tenant_id||options.fleetId) && goToTelematics(val)"
                >
                  <div :class="['largeItemsContentBoxes', getIconColor(val)]">
                    <div class="iconBox">
                      <Icons name="grid-box" />
                    </div>
                    <div class="contentBox">
                      <p :class="(options.tenant_id||options.fleetId) ? 'open-link':''">
                        <span>Total</span> {{ $t(`vehicle_${val}`) }}
                      </p>
                    </div>
                    <div class="valueBox">
                      {{ dashboadVehicleStatus[val] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
    <Modal
      v-if="isActiveModal"
      title="active_trips"
      :is-visible="isActiveModal"
      is-view
      :toggle="() => (isActiveModal = false)"
    >
      <active-trips :options="options" />
    </Modal>
    <!-- custom Html End-->
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  components: {
    draggable,
    Icons: () => import("@/components/base/icons.vue"),
    Modal: () => import("@/components/Modals/Modal.vue"),
    ApexDonutChart: () => import("@/components/sections/ApexDonutChart.vue"),
    ActiveTrips: () => import("../utils/ActiveTrips.vue"),
    ApexBarChartWithPlotOptions: () => import("@/components/sections/ApexBarChartWithPlotOptions.vue"),
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    vehicleTech: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      isActiveModal: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "dashboard/getIsLoading",
      dashboadData: "dashboard/getDashboadData",
      dashboadVehicleStatus: "dashboard/getVechileStatus",
      dashboadVehicleTrips: "dashboard/getVechileTrips",
      dashboardSort: "dashboard/getDashboadSortData",
      selectedCards: "dashboard/getSelectedCards",
    }),
    sectionTwo() {
      return this.dashboardSort?.section_2;
    },
    isDisable() {
      return this.selectedCards.length == 0 ? true : false;
    },
  },
  methods: {
    getIconName(key) {
      if (key == "active_trips") {
        return "ev-distance-efficiency"
      } else if (key == "active_distance") {
        return "travel"
      } else if (key == "all_trips") {
        return "total-average-speed"
      } else if (key == "total_distance") {
        return "gap"
      }
    },

    openActiveModal() {
      this.isActiveModal = true;
    },

    getIconColor(key) {
      if (key == "active_trips" || key =='total_count') {
        return "blueBg"
      } else if (key == "active_distance" || key =='active_vehicles') {
        return "greenBg"
      } else if (key == "all_trips" || key =='in_active_vehicles') {
        return "orangeBg"
      } else if (key == "total_distance" || key =='offline_vehicles' ) {
        return "redBg"
      }
    },

    goToTelematics(val) {
      const params = {};
      if (this.authUser.isAdmin) {
        if (this.options.product == "oem") {
          params.product = this.options.product;
          params.tenant_id = this.options.tenant_id;
        } else if (this.options.product == "fleet") {
          params.product = this.options.product;
          params.fleetId = this.options.fleetId;
          params.oem_b2b_ids = this.options.oem_b2b_ids;
          params.tenant_id = this.options.tenant_id;
        }
      }
      //if (this.authUser.user_nature == "fleet")
      else {
        params.tenant_id = this.options.tenant_id;
      }
      params.md_make_id = this.options.md_make_id;
      params.md_model_id = this.options.md_model_id;
      if (val == "active_vehicles") {
        params.status = "active";
      } else if (val == "in_active_vehicles") {
        params.status = "inactive";
      } else if (val == "offline_vehicles") {
        params.status = "offline";
      }

      return this.$router.push({
        name: "vehicle_telematics",
        query: params,
      });
    },
    editItem() {
      const params = {};
      if (this.authUser.isAdmin) {
        if (this.options.product == "oem") {
          params.product = this.options.product;
          params.tenant_id = this.options.tenant_id;
        } else if (this.options.product == "fleet") {
          params.product = this.options.product;
          params.fleetId = this.options.fleetId;
          params.oem_b2b_ids = this.options.oem_b2b_ids;
          params.tenant_id = this.options.tenant_id;
        }
      }
      else {
        params.tenant_id = this.options.tenant_id;
      }
      params.md_make_id = this.options.md_make_id;
      params.md_model_id = this.options.md_model_id;

      return this.$router.push({
        name: "trips",
        query: params,
      });
    },
    updateOrder() {
      const params = {
        section: "section_2",
        sort: true,
        item: this.dashboardSort.section_2,
        vehicle_tech: this.vehicleTech,
      };

      this.$store.dispatch("dashboard/updateCard", params);
    },
    changeCardColor(row) {
      const params = {
        section: "section_2",
        user_card_id: row.id,
        card_color_code: row.card_color_code,
      };
      this.$store.dispatch("dashboard/updateCard", params);
    },
  },
};
</script>
<style lang="sass" scoped>

.section-two-in-active
  .v-card
    cursor: auto !important

.section-two
  user-select: none
  .v-card
    cursor: all-scroll
    transition: 0.7s all ease
    background: #d5f1d5 !important
    &:hover
      box-shadow: 2px 1px 8px #20a390 !important
      transform : scale3d(1.2, 1.3, 1.9)

    label
      font-size: 12px
    hr
      background: green
    span
      font-size: 16px
      font-weight: 600
</style>
